#directory-header {
	display: none;
}

#directory-header-breadcrumb {
	margin-bottom: 0.5rem;
}

#directory-home-tools,
#directory-header-tools {
	@extend .list-inline, .d-print-none;
	text-align: right;
	li {
		@extend .list-inline-item;
		font-size: 0.875rem;
		a {
			color: $martinique;
			text-decoration: none;
			font-weight: 500;
			background: none !important;
			padding-left: 0 !important;
			&:hover {
				text-decoration: underline;
			}
			&::before {
				font-family: "pbn-icons";
				font-size: 0.875rem;
				display: inline-block;
				margin-right: 0.25rem;
			}
			&.admin {
				&::before {
					content: $pbn-icon-settings;
				}
			}
			&.print {
				&::before {
					content: $pbn-icon-printer;
				}
			}
			&.add {
				&::before {
					content: $pbn-icon-add;
				}
			}
			&.email {
				&::before {
					content: $pbn-icon-email;
				}
			}
			&.pdf {
				&::before {
					content: $pbn-icon-pdf;
				}
			}
		}
	}
}

#directory-home-form {
	background: none !important;
	#directory-home-form-inner {
		@extend .row;
		#directory-home-form-divider {
			display: none;
		}
		#directory-home-form-zipcode,
		#directory-home-form-detention {
			form {
				padding: 1rem 0.5rem 1rem 0.5rem;
				background-color: $danube;
				table {
					margin: auto;
					td {
						label {
							@extend .text-wrap;
							display: none;
							@include media-breakpoint-up(md) {
								display: inline;
							}
							color: $white;
							text-transform: uppercase;
							font-size: 0.875rem;
							margin-right: 0.25rem;
						}
						input[type="text"] {
							@extend .form-control, .form-control-sm;
						}
						select {
							@extend .form-select, .form-select-sm;
							max-width: 210px;
						}
						.btn {
							margin-left: 0.25rem;
							color: $white;
						}
					}
				}
			}
		}
		#directory-home-form-zipcode {
			@extend .col-12, .col-lg-5;
			form {
				border-radius: 1rem 1rem 0 0;
				padding-top: 2rem;
				@include media-breakpoint-up(lg) {
					border-radius: 0;
					padding-top: 1rem;
				}
				h4 {
					display: block !important;
					text-align: center;
					color: $white;
					margin-bottom: 2rem;
					font-size: 2rem;
					@include media-breakpoint-up(md) {
						display: none !important;
					}
				}
			}
		}
		#directory-home-form-detention {
			@extend .col-12, .col-lg-7;
			form {
				padding-bottom: 2rem;
				margin-bottom: 1rem;
				@include media-breakpoint-up(lg) {
					padding-bottom: 1rem;
				}
			}
		}
	}
}

#directory-home-summary {
	padding: 1rem;
	display: none;
	@include media-breakpoint-up(md) {
		display: block;
	}
	#directory-home-map {
		padding: 1rem;
		text-align: center;
		background: url(../images/map2.png) no-repeat center;
		img {
			opacity: 0;
		}
	}
}

#directory-search-tabs {
	background-color: $danube !important;
	background-image: none !important;
	padding-top: 1rem;
	padding-left: 0.5rem;
	ul {
		@extend .nav, .nav-tabs;
		li {
			@extend .nav-item;
			a {
				@extend .nav-link;
			}
			&.current {
				a {
					@extend .active;
				}
			}
			&:not(.current) {
				a {
					color: $white;
					&:hover {
						background-color: $white;
						color: $martinique;
					}
				}
			}
		}
	}
}

#directory-search-results {
	#directory-navigation-top,
	#directory-navigation-bottom {
		padding-bottom: 1rem;
		margin-top: 1rem;
		@extend .d-flex,
			.align-items-center,
			.justify-content-between,
			.flex-wrap;
		div {
			@extend .pagination;
			> span {
				@extend .page-item;
				a,
				span {
					@extend .page-link;
				}
				span {
					@extend .disabled;
				}
			}
		}
	}
	#directory-navigation-top {
		@extend .border-bottom;
	}
}

#directory-search-form-bottom {
	margin-bottom: 1rem;
	h3 {
		background-color: $concrete;
		padding: 0.25rem 0.5rem;
	}
	.directory-search-form-bottom-field {
		border: 1px solid $concrete;
		margin-bottom: 0.5rem;
		.field-header {
			background-color: $concrete;
			display: block;
			padding: 0.25rem 0.5rem;
			cursor: pointer;
			&::after {
				font-family: "pbn-icons";
				content: $pbn-icon-arrow-down;
				float: right;
			}
			&.open {
				&::after {
					content: $pbn-icon-arrow-up;
				}
			}
		}
		.directory-search-form-bottom-field-wrapper {
			font-size: 0.875rem;
			padding: 1rem 1.25rem 1rem 1.25rem;
			display: none;
			overflow: hidden;
			&.shown {
				display: block;
			}
			table {
				width: 100%;
				tr {
					@extend .row;
					td {
						&.checkbox {
							@extend .col-1;
							text-align: right;
							padding-right: 0;
						}
						&.label {
							@extend .col-11, .col-md-5;
							padding-left: 0.25rem;
						}
					}
				}
			}
			input[type="text"],
			select {
				width: 100%;
			}
		}
	}
	.controls {
		padding-top: 0.5rem;
		text-align: right;
		.submit {
			@extend .btn, .btn-primary;
			margin-left: 1rem;
		}
	}
}

#directory-organization-details {
	margin-bottom: 0.5rem;
	h3,
	h4 {
		color: $vermilion !important;
	}
	#organization-detail-name {
		background: none !important;
	}
	.organization-detail-section {
		@extend .border-bottom;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		table {
			width: 100%;
			font-size: 0.875rem;
			tr {
				@extend .row;
				&:not(:last-child) {
					margin-bottom: 0.25rem;
				}
				td {
					&.label {
						font-weight: 600;
					}
				}
			}
			&:not(#organization-detail-contact) {
				tr {
					td {
						@extend .col-12, .col-md-9;
						&.label {
							@extend .col-12, .col-md-3;
						}
					}
				}
			}
			&#organization-detail-contact {
				tr {
					td {
						@extend .col-12;
						ul {
							@extend .list-unstyled;
						}
						&:not(:last-child) {
							@extend .col-sm-6, .col-lg-2;
						}
						&:last-child {
							@extend .col-lg-3;
						}
					}
				}
			}
		}
	}
	#organization-detail-map,
	#organization-detail-vcard,
	#organization-detail-feedback {
		background-color: initial;
		margin-bottom: 0.35rem;
		a {
			background: none !important;
			color: $royal-blue !important;
			text-decoration: underline;
			padding-left: 0;
			&:hover {
				text-decoration: none;
				color: $sail !important;
			}
			&::before {
				font-family: "pbn-icons";
				display: inline-block;
				margin-right: 0.5rem;
			}
		}
	}
	#organization-detail-map {
		a::before {
			content: $pbn-icon-map-pin;
		}
	}
	#organization-detail-vcard {
		a::before {
			content: $pbn-icon-download;
		}
	}
	#organization-detail-feedback {
		a::before {
			content: $pbn-icon-email;
		}
	}
}

#organization-detail-name {
	background: none !important;
	h3 {
		color: $vermilion !important;
	}
}

.directory-organization-summary {
	@extend .border-bottom;
	padding: 1rem 0;
	h4 {
		a {
			color: $vermilion;
		}
	}
	table {
		width: 95%;
		font-size: 0.875rem;
		tr {
			@extend .row;
			&:not(:last-child) {
				margin-bottom: 0.25rem;
			}
			td {
				@extend .col-12, .col-md-9;
				&.label {
					@extend .col-12, .col-md-3;
					font-weight: 600;
				}
			}
		}
	}
}
