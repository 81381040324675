.header {
	.header-top {
		padding: 1rem 0 1rem 0;
		.lawhelp-logo {
			a {
				font-family: Arial, Helvetica, sans-serif;
				font-weight: 600;
				text-decoration: none;
				color: $martinique;
				span {
					color: $royal-blue;
				}
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.language-selector {
			select {
				@extend .form-select, .form-select-sm;
			}
		}
	}
	.header-main {
		padding: 1rem 0 1rem 0;
		h5 {
			font-size: 1.1rem;
			margin-bottom: 1rem;
			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
			}
		}
		.language-selector {
			margin: 0 0 1rem auto;
			select {
				@extend .form-select, .form-select-sm;
			}
		}
		.site-nav {
			ul {
				margin-bottom: 0.5rem;
				@include media-breakpoint-up(lg) {
					margin-bottom: 0;
				}
			}
			a {
				font-size: 0.875rem;
				font-weight: 600;
				text-decoration: none;
				color: $martinique;
				&:hover {
					text-decoration: underline;
				}
			}
			li:not(:last-child) {
				margin-right: 1rem;
			}
		}
	}
}
