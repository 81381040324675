.footer {
	margin-top: 1rem;
	margin-bottom: 3rem;
	.partners {
		ul:not(:last-child) {
			margin-right: 2rem;
		}
		.lab {
			img {
				max-width: 150px;
			}
		}
		.probono {
			font-family: $font-family-georgia;
			font-size: 1.5rem;
			a {
				color: $carnation;
				text-decoration: none;
			}
		}
	}
}
