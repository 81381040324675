@font-face {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat-VariableFont_wght.woff2") format("woff2"),
		url("../fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
	font-style: normal;
	font-weight: 100 700;
}
@font-face {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat-Italic-VariableFont_wght.woff2")
			format("woff2"),
		url("../fonts/Montserrat-Italic-VariableFont_wght.ttf")
			format("truetype");
	font-style: italic;
	font-weight: 100 700;
}
