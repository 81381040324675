/*! immigration-lawhelp */

// Bootstrap functions
@import "bootstrap/scss/functions";

// Bootstrap variable overrides
$link-decoration: underline;
$link-hover-decoration: none;
$font-family-sans-serif: "Helvetica Neue", system-ui, -apple-system, "Segoe UI",
	Roboto, Arial, "Noto Sans", "Liberation Sans", sans-serif,
	"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$breadcrumb-divider: quote(">");
$enable-negative-margins: true;

// Other global variables
$font-family-georgia: Georgia, Verdana, Arial, sans-serif;
$carnation: #f14e53;

// Bootstrap variables
@import "bootstrap/scss/variables";

// Palette colors
$martinique: #33294a;
$royal-blue: #2364e1;
$sail: #aac8fa;
$vermilion: #ff4800;
$dusty-gray: #999999;
$concrete: #f2f2f2;
$danube: #6089c6;
$custom-colors: (
	"martinique": $martinique,
	"royal-blue": $royal-blue,
	"sail": $sail,
	"vermilion": $vermilion,
	"dusty-gray": $dusty-gray,
	"concrete": $concrete,
	"danube": $danube,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

// Bootstrap colors
$body-bg: $white;
$body-color: $martinique;
$link-color: $royal-blue;
$link-hover-color: $sail;

// Bootstrap theme colors
$primary: $martinique;
$secondary: $royal-blue;
$custom-theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
);
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

// Bootstrap required
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Bootstrap optional
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
//@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
//@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
//@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/list-group";
//@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
//@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
//@import "bootstrap/scss/placeholders";

// Bootstrap helpers
@import "bootstrap/scss/helpers";

// Bootstrap utilities api
@import "bootstrap/scss/utilities/api";

// NProgress
@import "nprogress/nprogress";

// Icon set
@import "../../pbn-icons/scss/app";

// Site styles
@import "abstracts/all";
@import "base/all";
@import "components/all";
@import "layout/all";
@import "pages/all";
