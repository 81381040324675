// App header
#m-header {
	.page-tools {
		@extend .list-inline, .d-print-none, .d-none, .d-md-block;
		margin-top: 1rem;
		margin-left: auto;
		margin-bottom: 0.5rem;
		text-align: right;
		font-size: 0.875rem;
		li {
			@extend .list-inline-item;
			margin-left: 0.75rem;
			a {
				text-transform: capitalize;
				color: $martinique;
				&::before {
					font-family: "pbn-icons";
					display: inline-block;
					margin-right: 0.25rem;
				}
				&.add-folder {
					&::before {
						content: $pbn-icon-folder-add;
					}
				}
				&.add-item {
					&::before {
						content: $pbn-icon-add;
					}
				}
				&.add-multiple {
					&::before {
						content: $pbn-icon-add $pbn-icon-add;
					}
				}
				&.admin,
				&.settings,
				&.widget {
					&::before {
						content: $pbn-icon-settings;
					}
				}
				&.email,
				&.letter {
					&::before {
						content: $pbn-icon-email;
					}
				}
				&.external-content {
					&::before {
						content: $pbn-icon-external-link;
					}
				}
				&.favorites,
				&.flag-on,
				&.flag-off {
					&::before {
						content: $pbn-icon-bookmarks;
					}
				}
				&.ical {
					&::before {
						content: $pbn-icon-calendar;
					}
				}
				&.hide-sidebar {
					&::before {
						content: $pbn-icon-cross-light;
					}
				}
				&.home {
					&::before {
						content: $pbn-icon-home;
					}
				}
				&.labels {
					&::before {
						content: $pbn-icon-tags;
					}
				}
				&.mailing-definitions {
					&::before {
						content: $pbn-icon-inbox;
					}
				}
				&.member {
					&::before {
						content: $pbn-icon-member;
					}
				}
				&.my-cases {
					&::before {
						content: $pbn-icon-cases;
					}
				}
				&.print {
					content: "print page";
					&::before {
						content: $pbn-icon-printer;
					}
				}
				&.reports {
					&::before {
						content: $pbn-icon-resources;
					}
				}
				&.rss {
					&::before {
						content: $pbn-icon-rss;
					}
				}
			}
		}
	}
}
