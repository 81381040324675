.fav-icon {
	background-image: url(../images/Favicon_LH.png);
}
.justicia-lab {
	background-image: url(../images/JL_PurplePink.png);
}
.freedoms {
	background-image: url(../images/footer_fff.gif);
}
.ilh-logo {
	background-image: url(../images/ILH_color.svg);
}
.dir-map {
	background-image: url(../images/map.png);
}
.dir-map2 {
	background-image: url(../images/map2.png);
}

#back-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	color: $white;
	background-color: $royal-blue;
	border-color: $royal-blue;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
	&:hover,
	&:active {
		color: $white;
		background-color: tint-color($royal-blue, 20);
		border-color: tint-color($royal-blue, 20);
	}
	span {
		font-size: 0.875rem;
	}
}

.success-msg,
#site-message {
	@extend .alert, .alert-success;
}

#frm-error {
	@extend .alert, .alert-warning;
	h4,
	dl {
		margin-left: 3rem;
		padding: 0;
	}
	dl {
		margin-bottom: 0;
	}
}
