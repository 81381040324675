[class^="pbn-icon-"],
[class*=" pbn-icon-"] {
	font-family: "pbn-icons" !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: inherit;
	font-size: 1.25rem;
	width: 24px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.pbn-icon-folder-remove {
	&:before {
		content: $pbn-icon-folder-remove;
	}
}
.pbn-icon-user-add {
	&:before {
		content: $pbn-icon-user-add;
	}
}
.pbn-icon-folder-add {
	&:before {
		content: $pbn-icon-folder-add;
	}
}
.pbn-icon-subtract {
	&:before {
		content: $pbn-icon-subtract;
	}
}
.pbn-icon-settings {
	&:before {
		content: $pbn-icon-settings;
	}
}
.pbn-icon-add {
	&:before {
		content: $pbn-icon-add;
	}
}
.pbn-icon-clipboard {
	&:before {
		content: $pbn-icon-clipboard;
	}
}
.pbn-icon-copy {
	&:before {
		content: $pbn-icon-copy;
	}
}
.pbn-icon-delete {
	&:before {
		content: $pbn-icon-delete;
	}
}
.pbn-icon-edit {
	&:before {
		content: $pbn-icon-edit;
	}
}
.pbn-icon-lhi {
	&:before {
		content: $pbn-icon-lhi;
	}
}
.pbn-icon-lsc {
	&:before {
		content: $pbn-icon-lsc;
	}
}
.pbn-icon-tiktok {
	&:before {
		content: $pbn-icon-tiktok;
	}
}
.pbn-icon-facebook {
	&:before {
		content: $pbn-icon-facebook;
	}
}
.pbn-icon-instagram {
	&:before {
		content: $pbn-icon-instagram;
	}
}
.pbn-icon-linkedin {
	&:before {
		content: $pbn-icon-linkedin;
	}
}
.pbn-icon-pinterest {
	&:before {
		content: $pbn-icon-pinterest;
	}
}
.pbn-icon-twitter {
	&:before {
		content: $pbn-icon-twitter;
	}
}
.pbn-icon-vimeo {
	&:before {
		content: $pbn-icon-vimeo;
	}
}
.pbn-icon-youtube {
	&:before {
		content: $pbn-icon-youtube;
	}
}
.pbn-icon-link {
	&:before {
		content: $pbn-icon-link;
	}
}
.pbn-icon-arrow-right-2 {
	&:before {
		content: $pbn-icon-arrow-right-2;
	}
}
.pbn-icon-arrow-left-2 {
	&:before {
		content: $pbn-icon-arrow-left-2;
	}
}
.pbn-icon-folder-open {
	&:before {
		content: $pbn-icon-folder-open;
	}
}
.pbn-icon-folder-closed {
	&:before {
		content: $pbn-icon-folder-closed;
	}
}
.pbn-icon-checkmark {
	&:before {
		content: $pbn-icon-checkmark;
	}
}
.pbn-icon-inbox {
	&:before {
		content: $pbn-icon-inbox;
	}
}
.pbn-icon-download {
	&:before {
		content: $pbn-icon-download;
	}
}
.pbn-icon-cases {
	&:before {
		content: $pbn-icon-cases;
	}
}
.pbn-icon-calendar {
	&:before {
		content: $pbn-icon-calendar;
	}
}
.pbn-icon-members {
	&:before {
		content: $pbn-icon-members;
	}
}
.pbn-icon-announcement {
	&:before {
		content: $pbn-icon-announcement;
	}
}
.pbn-icon-sitemap {
	&:before {
		content: $pbn-icon-sitemap;
	}
}
.pbn-icon-member {
	&:before {
		content: $pbn-icon-member;
	}
}
.pbn-icon-logout {
	&:before {
		content: $pbn-icon-logout;
	}
}
.pbn-icon-map {
	&:before {
		content: $pbn-icon-map;
	}
}
.pbn-icon-arrow-down {
	&:before {
		content: $pbn-icon-arrow-down;
	}
}
.pbn-icon-arrow-left {
	&:before {
		content: $pbn-icon-arrow-left;
	}
}
.pbn-icon-arrow-right {
	&:before {
		content: $pbn-icon-arrow-right;
	}
}
.pbn-icon-arrow-up {
	&:before {
		content: $pbn-icon-arrow-up;
	}
}
.pbn-icon-bookmark {
	&:before {
		content: $pbn-icon-bookmark;
	}
}
.pbn-icon-bookmarks {
	&:before {
		content: $pbn-icon-bookmarks;
	}
}
.pbn-icon-classroom {
	&:before {
		content: $pbn-icon-classroom;
	}
}
.pbn-icon-cross {
	&:before {
		content: $pbn-icon-cross;
	}
}
.pbn-icon-cross-light {
	&:before {
		content: $pbn-icon-cross-light;
	}
}
.pbn-icon-email {
	&:before {
		content: $pbn-icon-email;
	}
}
.pbn-icon-external-link {
	&:before {
		content: $pbn-icon-external-link;
	}
}
.pbn-icon-grid {
	&:before {
		content: $pbn-icon-grid;
	}
}
.pbn-icon-handshake {
	&:before {
		content: $pbn-icon-handshake;
	}
}
.pbn-icon-home {
	&:before {
		content: $pbn-icon-home;
	}
}
.pbn-icon-map-pin {
	&:before {
		content: $pbn-icon-map-pin;
	}
}
.pbn-icon-menu {
	&:before {
		content: $pbn-icon-menu;
	}
}
.pbn-icon-menu-light {
	&:before {
		content: $pbn-icon-menu-light;
	}
}
.pbn-icon-printer {
	&:before {
		content: $pbn-icon-printer;
	}
}
.pbn-icon-rss {
	&:before {
		content: $pbn-icon-rss;
	}
}
.pbn-icon-scale {
	&:before {
		content: $pbn-icon-scale;
	}
}
.pbn-icon-search {
	&:before {
		content: $pbn-icon-search;
	}
}
.pbn-icon-share {
	&:before {
		content: $pbn-icon-share;
	}
}
.pbn-icon-tag {
	&:before {
		content: $pbn-icon-tag;
	}
}
.pbn-icon-tags {
	&:before {
		content: $pbn-icon-tags;
	}
}
.pbn-icon-thumbs-down {
	&:before {
		content: $pbn-icon-thumbs-down;
	}
}
.pbn-icon-thumbs-up {
	&:before {
		content: $pbn-icon-thumbs-up;
	}
}
.pbn-icon-translate {
	&:before {
		content: $pbn-icon-translate;
	}
}
.pbn-icon-type {
	&:before {
		content: $pbn-icon-type;
	}
}
.pbn-icon-audio {
	&:before {
		content: $pbn-icon-audio;
	}
}
.pbn-icon-excel {
	&:before {
		content: $pbn-icon-excel;
	}
}
.pbn-icon-html {
	&:before {
		content: $pbn-icon-html;
	}
}
.pbn-icon-image {
	&:before {
		content: $pbn-icon-image;
	}
}
.pbn-icon-openoffice {
	&:before {
		content: $pbn-icon-openoffice;
	}
}
.pbn-icon-pdf {
	&:before {
		content: $pbn-icon-pdf;
	}
}
.pbn-icon-powerpoint {
	&:before {
		content: $pbn-icon-powerpoint;
	}
}
.pbn-icon-text {
	&:before {
		content: $pbn-icon-text;
	}
}
.pbn-icon-video {
	&:before {
		content: $pbn-icon-video;
	}
}
.pbn-icon-word {
	&:before {
		content: $pbn-icon-word;
	}
}
.pbn-icon-zip {
	&:before {
		content: $pbn-icon-zip;
	}
}
.pbn-icon-hurricane {
	&:before {
		content: $pbn-icon-hurricane;
	}
}
.pbn-icon-fire {
	&:before {
		content: $pbn-icon-fire;
	}
}
.pbn-icon-storm {
	&:before {
		content: $pbn-icon-storm;
	}
}
.pbn-icon-coronavirus {
	&:before {
		content: $pbn-icon-coronavirus;
	}
}
.pbn-icon-advice {
	&:before {
		content: $pbn-icon-advice;
	}
}
.pbn-icon-aids {
	&:before {
		content: $pbn-icon-aids;
	}
}
.pbn-icon-animal-rights {
	&:before {
		content: $pbn-icon-animal-rights;
	}
}
.pbn-icon-art-culture {
	&:before {
		content: $pbn-icon-art-culture;
	}
}
.pbn-icon-artist {
	&:before {
		content: $pbn-icon-artist;
	}
}
.pbn-icon-audio-note {
	&:before {
		content: $pbn-icon-audio-note;
	}
}
.pbn-icon-call {
	&:before {
		content: $pbn-icon-call;
	}
}
.pbn-icon-community-dev {
	&:before {
		content: $pbn-icon-community-dev;
	}
}
.pbn-icon-community-dev-2 {
	&:before {
		content: $pbn-icon-community-dev-2;
	}
}
.pbn-icon-consumer {
	&:before {
		content: $pbn-icon-consumer;
	}
}
.pbn-icon-consumer-2 {
	&:before {
		content: $pbn-icon-consumer-2;
	}
}
.pbn-icon-credit {
	&:before {
		content: $pbn-icon-credit;
	}
}
.pbn-icon-criminal {
	&:before {
		content: $pbn-icon-criminal;
	}
}
.pbn-icon-criminal-2 {
	&:before {
		content: $pbn-icon-criminal-2;
	}
}
.pbn-icon-disability {
	&:before {
		content: $pbn-icon-disability;
	}
}
.pbn-icon-disaster {
	&:before {
		content: $pbn-icon-disaster;
	}
}
.pbn-icon-disaster-relief {
	&:before {
		content: $pbn-icon-disaster-relief;
	}
}
.pbn-icon-domestic-violence {
	&:before {
		content: $pbn-icon-domestic-violence;
	}
}
.pbn-icon-education {
	&:before {
		content: $pbn-icon-education;
	}
}
.pbn-icon-environmental-law {
	&:before {
		content: $pbn-icon-environmental-law;
	}
}
.pbn-icon-family {
	&:before {
		content: $pbn-icon-family;
	}
}
.pbn-icon-family-2 {
	&:before {
		content: $pbn-icon-family-2;
	}
}
.pbn-icon-faq {
	&:before {
		content: $pbn-icon-faq;
	}
}
.pbn-icon-farm {
	&:before {
		content: $pbn-icon-farm;
	}
}
.pbn-icon-farm-workers {
	&:before {
		content: $pbn-icon-farm-workers;
	}
}
.pbn-icon-forms {
	&:before {
		content: $pbn-icon-forms;
	}
}
.pbn-icon-gender-equality {
	&:before {
		content: $pbn-icon-gender-equality;
	}
}
.pbn-icon-globe {
	&:before {
		content: $pbn-icon-globe;
	}
}
.pbn-icon-government {
	&:before {
		content: $pbn-icon-government;
	}
}
.pbn-icon-guide {
	&:before {
		content: $pbn-icon-guide;
	}
}
.pbn-icon-health {
	&:before {
		content: $pbn-icon-health;
	}
}
.pbn-icon-help {
	&:before {
		content: $pbn-icon-help;
	}
}
.pbn-icon-housing {
	&:before {
		content: $pbn-icon-housing;
	}
}
.pbn-icon-immigration {
	&:before {
		content: $pbn-icon-immigration;
	}
}
.pbn-icon-individual-rights {
	&:before {
		content: $pbn-icon-individual-rights;
	}
}
.pbn-icon-individual-rights-2 {
	&:before {
		content: $pbn-icon-individual-rights-2;
	}
}
.pbn-icon-info {
	&:before {
		content: $pbn-icon-info;
	}
}
.pbn-icon-intellectual-property {
	&:before {
		content: $pbn-icon-intellectual-property;
	}
}
.pbn-icon-internet-fraud {
	&:before {
		content: $pbn-icon-internet-fraud;
	}
}
.pbn-icon-juvenile {
	&:before {
		content: $pbn-icon-juvenile;
	}
}
.pbn-icon-legal-research {
	&:before {
		content: $pbn-icon-legal-research;
	}
}
.pbn-icon-life-planning {
	&:before {
		content: $pbn-icon-life-planning;
	}
}
.pbn-icon-limited-english {
	&:before {
		content: $pbn-icon-limited-english;
	}
}
.pbn-icon-migrant-workers {
	&:before {
		content: $pbn-icon-migrant-workers;
	}
}
.pbn-icon-motor-vehicles {
	&:before {
		content: $pbn-icon-motor-vehicles;
	}
}
.pbn-icon-native-american {
	&:before {
		content: $pbn-icon-native-american;
	}
}
.pbn-icon-native-hawaiian {
	&:before {
		content: $pbn-icon-native-hawaiian;
	}
}
.pbn-icon-network {
	&:before {
		content: $pbn-icon-network;
	}
}
.pbn-icon-nonprofit-law {
	&:before {
		content: $pbn-icon-nonprofit-law;
	}
}
.pbn-icon-politics {
	&:before {
		content: $pbn-icon-politics;
	}
}
.pbn-icon-public-benefits {
	&:before {
		content: $pbn-icon-public-benefits;
	}
}
.pbn-icon-resources {
	&:before {
		content: $pbn-icon-resources;
	}
}
.pbn-icon-restart {
	&:before {
		content: $pbn-icon-restart;
	}
}
.pbn-icon-seniors {
	&:before {
		content: $pbn-icon-seniors;
	}
}
.pbn-icon-small-business {
	&:before {
		content: $pbn-icon-small-business;
	}
}
.pbn-icon-sms {
	&:before {
		content: $pbn-icon-sms;
	}
}
.pbn-icon-taxes {
	&:before {
		content: $pbn-icon-taxes;
	}
}
.pbn-icon-veteran {
	&:before {
		content: $pbn-icon-veteran;
	}
}
.pbn-icon-video-film {
	&:before {
		content: $pbn-icon-video-film;
	}
}
.pbn-icon-visit {
	&:before {
		content: $pbn-icon-visit;
	}
}
.pbn-icon-worker {
	&:before {
		content: $pbn-icon-worker;
	}
}
.pbn-icon-youth-rights {
	&:before {
		content: $pbn-icon-youth-rights;
	}
}
